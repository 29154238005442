<template>
  <b-sidebar id="stockImportForm" :visible="dialog" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop
    no-header right @change="(val) => visibility(val)">
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Importar plantilla
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeDialog" />

      </div>

      <!-- BODY -->
      <validation-observer ref="formValidation">
        <!-- Form -->
        <b-form class="p-2">
          <!-- Cliente -->
          <validation-provider #default="{ errors }" name="cliente" rules="required">
            <b-form-group label="Cliente" label-for="customer_id" :class="errors.length > 0 ? 'is-invalid' : null">
              <v-select v-model="form.customer" :options="customers" clearable trim :reduce="val => val.id"
                :state="errors.length > 0 ? false : null" label="name" @input="changeEntity(form.customer)" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="{ errors }" name="entidad" rules="required" v-if="form.customer">
            <b-form-group label="Entidad" label-for="entity" :class="errors.length > 0 ? 'is-invalid' : null">
              <v-select v-model="form.entity" :options="entities" clearable trim :reduce="val => val.value"
                :state="errors.length > 0 ? false : null" label="name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Archivo -->
          <validation-provider #default="{ errors }" name="archivo" rules="required">
            <b-form-group label="Archivo" label-for="file" :class="errors.length > 0 ? 'is-invalid' : null">
              <b-form-file v-model="form.file" placeholder="Ingrese el archivo diligenciado..."
                drop-placeholder="Suelte su archivo aquí..." accept=".xlsx" :state="errors.length > 0 ? false : null"
                :class="errors.length > 0 ? 'is-invalid' : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </template>
    <template v-if="!formDisabled" #footer>
      <b-button-toolbar justify class="p-2">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="importFile">
          Guardar
        </b-button>

        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="closeDialog">
          Cerrar
        </b-button>
      </b-button-toolbar>
    </template>
  </b-sidebar>
</template>

<script>
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'stockImportForm',
  components: {
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      entities: [],
      form: {
        customer: null,
        file: [],
        entity: null
      },
      newForm: true,
      formDisabled: false,
      dialog: false,
      customers: []
    }
  },
  inject: ['callCleanMethod'],
  methods: {
    changeEntity(pharmacia) {
      this.form.entity = null
      this.getEntities(pharmacia);
    },
    getEntities(pharmacia) {
      this.$http.get(`/entities/selects/` + pharmacia).then((res) => {
        this.entities = res.data.entities;
      });
    },
    visibility(val) {
      localize('es', es);
      if (!val) this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm()
    },
    importFile() {
      this.callCleanMethod();
      localize('es', es)
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          this.$emit('update:loading-status', true)
          this.dialog = false;
          let formData = new FormData();
          formData.append('file', this.form.file);
          formData.append('customer', this.form.customer);
          formData.append('entity', this.form.entity);
          this.$http.post('/stocks/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            this.dialog = false;
            this.$emit('update:loading-status', false)
            this.resetForm();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Inventario actualizado`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Archivo importado con éxito`,
              },
            })
          })
            .catch((error) => {
              this.dialog = false;
              this.$emit('update:loading-status', false)
              this.resetForm();
              this.$emit('viewErrors', error.response.data.errors)
            })
        }
      })
    },
    resetForm() {
      this.$refs.formValidation.reset()
      this.form = {
        customer: null,
        file: [],
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
