<template>
  <div v-if="errorStatus">
    <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error
        </h4>
        <div class="alert-body">
          <span>Error en la importación de los datos, a continuación puede ver el detalle de los errores.</span>
        </div>
      </b-alert>
      <b-card
        no-body
        class="mb-0"
        >
          <b-row class="p-1">
            <b-col cols="12" md="6">
              <h3 class="text-danger">Detalle de errores</h3>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="outline-success" class="btn-icon mr-1" @click="clean">
                  <feather-icon icon="MonitorIcon" /> Limpiar pantalla
                </b-button>
                <b-button variant="outline-danger" class="btn-icon mr-1" @click="downloadErrors">
                  <feather-icon icon="DownloadIcon" /> Descargar errores
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-table
            ref="cumsTable"
            class="position-relative"
            :items="dataTable"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="tableSettings.sortBy"
            show-empty
            empty-text="No se encontraron datos"
            :sort-desc.sync="tableSettings.sortDesc"
          />
      </b-card>
  </div>
</template>

<script>
export default {
  name: 'ErrorStockImport',
  data(){
    return {
      tableColumns: [
        { key: 'item', label: 'Item', sortable: true },
        { key: 'column', label: 'Columna', sortable: false },
        { key: 'row', label: 'Fila', sortable: true },
        { key: 'cell', label: 'celda', sortable: false },
        { key: 'value', label: 'Valor', sortable: false },
        { key: 'error', label: 'Error', sortable: false },
      ],
      sortBy: 'code',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      tableSettings: {
        searchQuery: '',
        perPage: 10,
        page: 1,
        sortBy: null,
        sortDesc: false,
        busy: false,
        status: null,
        customer: null
      },
      customers: [],
      file: [],
      loadingStatus: false,
      errorStatus: false,
      formError: {
        data: [],
      }
    }
  },
  methods: {
    clean() {
      this.errorStatus = false
      this.dataTable = []
    },
    downloadErrors() {
      this.$emit('update:loading-status', true)
      this.formError.data = this.dataTable
      this.$http.post('stocks/download-errors', this.formError,
        { responseType: 'blob' }).then((response) => {
          this.$emit('update:loading-status', false)
          this.formError.data = []
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'errores.xlsx');
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
    
      
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
