<template>
  <div>
    <b-card no-body class="mb-2 p-2">
      <b-container>
        <validation-observer ref="formFileValidation">
          <b-row v-if="loadingStatus">
            <b-col cols="12" sm="12">
              <b-button variant="primary" disabled class="mr-1" style="width: 100%;">
                <b-spinner small />
                Procesando...
              </b-button>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12" md="4">
              <b-button @click="openCumSearch" variant="info" class="btn-icon btn-block">
                <feather-icon icon="DownloadIcon" />
                <span> Buscar medicamentos</span>
              </b-button>
            </b-col>
            <b-col cols="12" md="4">
              <b-button  @click="downloadTemplate" variant="secondary" class="btn-icon btn-block">
                <feather-icon icon="DownloadIcon" /> Descargar Plantilla
              </b-button>
            </b-col>
            <b-col cols="12" md="4">
              <b-button variant="primary" class="btn-icon btn-block" @click="create">
                <feather-icon icon="PlusIcon" /> Importar Plantilla
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </b-card>
    <stock-import-form @viewErrors="viewErrors" ref="StockImportForm" :loading-status.sync="loadingStatus" />
    <error-stock-import ref="ErrorStockImport" :loading-status.sync="loadingStatus" />
    <cum-search-table 
      ref="cumSearchTable"
    />
  </div>
</template>

<script>
import {
  VBToggle,
} from 'bootstrap-vue';
import StockImportForm from './StockImportForm.vue'
import ErrorStockImport from './ErrorStockImport.vue';
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import { required, email, digits, integer } from '@validations'
import CumSearchTable from '../stock/CumsSearchTable.vue';

export default {
  name: 'Stock',
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    StockImportForm,
    ErrorStockImport,
    CumSearchTable,
  },
  data() {
    return {
      loadingStatus: false,
    }
  },
  provide() {
    return {
      callCleanMethod: this.callCleanMethod
    }
  },
  methods: {
    downloadCums() {
      this.loadingStatus = true
      this.$http.post('stocks/download-cums', this.fileForm,
        { responseType: 'blob' }).then((response) => {
          this.loadingStatus = false
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cums.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$swal({
                icon: "success",
                title: "Éxito",
                html: "Archivo descargado éxitosamente!",
              });
        })
    },
    downloadTemplate() {
      this.loadingStatus = true
      this.$http.post('stocks/download-template', this.fileForm,
        { responseType: 'blob' }).then((response) => {
          this.loadingStatus = false
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'plantilla.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$swal({
                icon: "success",
                title: "Éxito",
                html: "Archivo descargado éxitosamente!",
              });
        })
    },
    openCumSearch() {
      this.$refs.cumSearchTable.showModal();
    },
    viewErrors(errorsData) {
      this.$refs.ErrorStockImport.errorStatus = true
      this.$refs.ErrorStockImport.dataTable = errorsData
    },
    async getCustomers() {
      await this.$http.get('/users/get-customers')
        .then((response) => {
          this.customers = response.data
          this.$refs.StockImportForm.customers = response.data
        })
    },
    create() {
      this.$refs.StockImportForm.resetForm();
      this.$refs.StockImportForm.newForm = true;
      this.$refs.StockImportForm.formDisabled = false;
      this.$refs.StockImportForm.dialog = true;
    },
    callCleanMethod() {
      this.$refs.ErrorStockImport.clean();
    },
  },
  created() {
    this.getCustomers();
  },
} 
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
